<template>
  <v-layout wrap justify-start>
    <v-snackbar
      v-model="passwordCopied"
      :bottom="true"
      :right="true"
      :timeout="2500"
      color="success">
      Testo copiato!
    </v-snackbar>
    <v-snackbar
      v-model="errorSnack"
      :bottom="true"
      :right="true"
      :timeout="2500"
      color="error">
      {{ errorSnackText }}
    </v-snackbar>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/home-green.svg" cover height="30vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="heading">Compagnie</div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center class="product-container">
        <v-flex xs12 sm9 md10 lg9>
          <v-layout wrap justify-center>
            <div class="aaa">
              <div
                v-for="item in productsByCompany"
                :key="item.id"
                style="break-inside: avoid; padding: 20px 0">
                <AppCard
                  class="pa-6"
                  style="
                    min-height: 100%;
                    display: flex;
                    flex-direction: column;
                  ">
                  <v-flex
                    xs12
                    class="card-title pb-2"
                    :class="{ 'pb-2': Boolean(item.logo) }">
                    <v-row>
                      <img
                        v-if="item.logo"
                        :src="item.logo"
                        style="
                          width: 50px;
                          height: 50px;
                          border-radius: 50%;
                          margin-left: 12px;
                          background-color: black;
                        " />

                      <v-col class="p-0">{{ item.name }}</v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="item.description" xs12 pt-2 class="normal-text">
                    <span v-html="item.description"></span>
                  </v-flex>
                  <v-flex v-if="item.products && item.products.length > 0">
                    <span class="card-title text-subtitle-1">
                      <b>Prodotti</b>
                    </span>
                    <a
                      v-for="prod in item.products"
                      :key="prod.id"
                      class="d-block product-link"
                      @click="goExternal(prod.url ?? item.url, prod.id)">
                      {{ prod.title }}
                    </a>
                  </v-flex>
                  <v-layout justify-end mt-4>
                    <v-btn
                      color="primary"
                      darkq
                      depressed
                      :ripple="false"
                      @click="
                        showCredentialsModal(
                          item.name,
                          item.username,
                          item.password,
                          item.url ? item.url : null
                        )
                      ">
                      ottieni le credenziali &nbsp;
                      <v-icon>key</v-icon>
                    </v-btn>
                  </v-layout>
                </AppCard>
              </div>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- <v-layout wrap justify-center class="product-container">
        <v-flex xs12 md9>
          <v-layout wrap justify-start v-if="authState">
            <v-flex
              xs12
              sm6
              md4
              pa-4
              v-for="item in productsByCompany"
              :key="item.id"
              style="align-items: stretch">
              <AppCard
                class="pa-6"
                style="min-height: 100%; display: flex; flex-direction: column">
                <v-flex xs12 class="card-title">
                  <v-row>
                    <img
                      v-if="item.logo"
                      :src="item.logo"
                      style="width: 50px; height: 50px; border-radius: 50%" />

                    <v-col>{{ item.name }}</v-col>
                  </v-row>
                </v-flex>
                <v-flex v-if="item.description" xs12 py-2 class="normal-text">
                  <span v-html="item.description"></span>
                </v-flex>
                <v-flex>
                  <span class="card-title text-subtitle-1">
                    <b>Prodotti</b>
                  </span>
                  <a
                    v-for="prod in item.products"
                    :key="prod.id"
                    class="d-block"
                    :href="prod.url ?? item.url">
                    {{ prod.title }}
                  </a>
                </v-flex>
                <v-layout justify-end mt-4>
                  <v-btn
                    color="primary"
                    darkq
                    depressed
                    :ripple="false"
                    @click="
                      showCredentialsModal(
                        item.name,
                        item.username,
                        item.password,
                        item.url ? item.url : null
                      )
                    ">
                    ottieni le credenziali &nbsp;
                    <v-icon>key</v-icon>
                  </v-btn>
                </v-layout>
              </AppCard>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout> -->
      <AppDialog
        :value="isCredentialDialogOpen"
        @close="disposeCredentialsModal">
        <AppCard class="pa-6">
          <v-layout wrap justify-start>
            <v-flex xs12 text-right>
              <v-icon @click="disposeCredentialsModal">mdi-close</v-icon>
            </v-flex>
            <v-flex
              v-if="selectedCredentials"
              xs12
              class="card-title primary-text">
              Credenziali di accesso al portale di
              <br />
              {{ selectedCredentials.company }}
            </v-flex>
            <v-flex
              xs12
              py-2
              class="normal-text"
              v-if="selectedCredentials && selectedCredentials.username">
              <v-text-field
                :value="selectedCredentials.username"
                label="Username"
                readonly
                append-icon="content_copy"
                @click:append="
                  copy(selectedCredentials.username)
                "></v-text-field>
            </v-flex>
            <v-flex
              xs12
              py-2
              class="normal-text"
              v-if="selectedCredentials && selectedCredentials.password">
              <v-text-field
                :value="selectedCredentials.password"
                label="Password"
                type="password"
                readonly
                prepend-icon="key"
                append-icon="content_copy"
                @click:append="copy(selectedCredentials.password)"
                @click:prepend="togglePassword($event)"></v-text-field>
              <span></span>
            </v-flex>
            <v-flex
              xs12
              pt-2
              style="margin-top: auto"
              v-if="selectedCredentials && selectedCredentials.url">
              <a :href="selectedCredentials.url" target="_blank">
                <v-btn color="primary" dark :ripple="false" depressed>
                  <span class="text-capitalize">Accedi al portale</span>
                </v-btn>
              </a>
            </v-flex>
          </v-layout>
        </AppCard>
      </AppDialog>
    </v-flex>
  </v-layout>
</template>

<script>
import AppCard from "@/components/shared/AppCard";
import { mapGetters } from "vuex";
import AppDialog from "../components/shared/AppDialog.vue";
import ExternalGateway from "../externalGateway";

export default {
  name: "Conventions",
  components: {
    AppCard,
    AppDialog,
  },
  data() {
    return {
      isCredentialDialogOpen: false,
      selectedCredentials: null,
      passwordCopied: false,
      editingCompany: false,
      deletingCompany: false,
      errorSnack: false,
      errorSnackText: "",
    };
  },
  computed: {
    ...mapGetters({
      items: "getCompanies",
      authState: "getAuthState",
      isStaff: "getIsStaff",
      productsByCompany: "getProductsByCompany",
    }),
  },
  mounted() {
    console.log(this.productsByCompany);
  },
  methods: {
    copy: function (value) {
      navigator.clipboard.writeText(value);
      this.passwordCopied = true; // Notifica l'utente
    },
    showCredentialsModal: function (company, username, password, url) {
      this.isCredentialDialogOpen = true;
      this.selectedCredentials = {
        company: company,
        username: username,
        password: password,
        url: url,
      };
    },
    disposeCredentialsModal: function () {
      this.isCredentialDialogOpen = false;
      this.selectedCredentials = null;
    },
    togglePassword: function (event) {
      const passwordInput =
        event.currentTarget.parentElement.parentElement.parentElement.querySelector(
          "input"
        );
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    goExternal: function (url, productId) {
      ExternalGateway.goExternal(this.$store, url, productId)
        .then((navUrl) => {
          ExternalGateway.navigate(navUrl);
        })
        .catch((error) => {
          this.errorSnack = true;
          this.errorSnackText = error.data.error || error.message || error;
        });
    },
    // addCompany: function(){
    //   this.editingCompany = {
    //     username: '', password: '', name: '', description: '', url: ''
    //   }
    // },
    // saveEditingCompany: function(){
    //   this.$store.dispatch("updateCompany", this.editingCompany).finally(()=>{
    //     this.editingCompany = false
    //   })
    // },
    // deleteCompany: function(){
    //   this.$store.dispatch("deleteCompany", this.deletingCompany).finally(()=>{
    //     this.deletingCompany = false
    //   })
    // }
  },
};
</script>

<style scoped>
.aaa {
  row-gap: 10px;
  column-count: 2;
  column-gap: 10px;
}
@media (max-width: 600px) {
  .aaa {
    column-count: 1;
  }
}

.product-link:hover {
  text-decoration: underline !important;
}
</style>
